import { editParameterInUrl } from './url';

export type Resolution = 'performance' | 'quality';
export type PageType = 'my-library' | 'wishlist' | 'search' | 'core-search' | 'currently-reading' | 'book-details' | 'explore' | 'portfolio' | 'sidebar';
export type ResolutionSelector = 'large-grid' | 'medium-grid' | 'small-grid' | 'list' | 'current' | 'queue' | 'aside' | 'slider' | 'grid';

export const editGoogleImageResolution = (
	image: string | null | undefined,
	resolution: Resolution,
	pageType: PageType,
	resolutionSelector?: ResolutionSelector,
): string | null => {
	if (!image)
		return null;

	image = editParameterInUrl(image, 'zoom', '1');
	if (resolutionSelector) {
		const [performance, quality] = wValues[pageType][resolutionSelector];
		return editParameterInUrl(image, 'fife', `w${(resolution === 'performance' ? performance : quality)}`);
	}

	return image;
};

const wValues = {
	'my-library': {
		'large-grid': [230, 300],
		'medium-grid': [180, 190],
		'small-grid': [124, 140],
		'list': [80, 90],
	},
	'wishlist': {
		'large-grid': [230, 300],
		'medium-grid': [180, 190],
		'small-grid': [124, 140],
		'list': [80, 90],
	},
	'search': {
		'large-grid': [320, 320],
		'medium-grid': [252, 300],
		'small-grid': [176, 190],
		'list': [80, 90],
	},
	'core-search': {
		list: [48, 48],
	},
	'currently-reading': {
		current: [140, 190],
		queue: [80, 90],
	},
	'book-details': {
		aside: [240, 300],
	},
	'explore': {
		slider: [230, 300],
	},
	'portfolio': {
		current: [140, 190],
	},
	'sidebar': {
		grid: [180, 190],
	},
};
